"use client"

import { createContext, useContext, type PropsWithChildren } from "react"

import type { TCart } from "~/components/ui/CartPanel/_data/serializer"

const CartEditoContext = createContext<Partial<TCart> | null>(null)

export function useCartEdito() {
  const context = useContext(CartEditoContext)

  if (!context) {
    throw new Error("useGlobalProduct must be used within a GlobalProductContext")
  }

  return context
}

export function CartEditoProvider({ children, cart }: PropsWithChildren<{ cart: TCart }>) {
  return (
    // if our initial cart value is null,
    // return an empty object with the same shape as our context, with nullable values
    <CartEditoContext.Provider value={cart ?? ({} as Partial<NonNullable<TCart>>)}>
      {children}
    </CartEditoContext.Provider>
  )
}
