import { clsx } from "clsx"

import type { UnlikelyMoney } from "@unlikelystudio/commerce-connector"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import serializePrice from "~/data/price/serializer"
import useLocale from "~/hooks/useLocale"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

type TotalPriceProps = PropsWithClassName<{
  totalDiscount: Nullish<UnlikelyMoney>
}>

function TotalPrice({ className, totalDiscount }: TotalPriceProps) {
  const t = useTranslate()
  const locale = useLocale()
  const discountPrice = totalDiscount
    ? serializePrice(locale, totalDiscount?.currencyCode, totalDiscount?.amount)
    : null
  if (!discountPrice) return null

  return (
    <div className={clsx(css.TotalPrice, className)}>
      {totalDiscount && (
        <p className={clsx(css.totalDiscount)}>
          <span>{t("cart_total_discount")}</span>
          <span className={clsx(css.price)}>{`- ${discountPrice}`}</span>
        </p>
      )}
    </div>
  )
}

export default TotalPrice
