"use client"

import { createRef } from "react"
import { clsx } from "clsx"

import { useIsHover } from "@unlikelystudio/react-hooks"
import Slider from "@unlikelystudio/react-slider"

import type { PropsWithClassName } from "~/@types/generics"
import { useFetchSbStoryQuery } from "~/lib/storyblok/hooks/useFetchSbStoryQuery"
import useLocale from "~/hooks/useLocale"
import { serializeReinsuranceSlider } from "~/components/ui/ProductHeader/components/ProductDetails/components/ReinsuranceSlider/_data/serializer"
import { FooterReassuranceItem } from "~/components/globals/Footer/components/FooterReassurance/components/FooterReassuranceItem"

import * as css from "./styles.css"

export type ReinsurranceSliderProps = PropsWithClassName<{}>

function ReinsurranceSlider({ className }: ReinsurranceSliderProps) {
  const locale = useLocale()
  const containerRef = createRef<HTMLDivElement>()
  const [isHovered, callbacks] = useIsHover()

  const { data } = useFetchSbStoryQuery(["globals/reinsurance_slider", { locale }, {}], {
    keepPreviousData: true,
  })

  const serializedReinsuranceSlider = serializeReinsuranceSlider(data?.data.story.content, locale)
  if (!serializedReinsuranceSlider) return null

  const customSliderRef = createRef<HTMLDivElement>()
  return (
    <div ref={containerRef} className={clsx(className, css.ReinsurranceSlider)} {...callbacks}>
      <Slider
        className={clsx(css.slider)}
        infinite={true}
        autoIsSlider={false}
        customSliderRef={customSliderRef}
        autoPlay={{ speed: isHovered ? 0 : -0.4 }}
      >
        {serializedReinsuranceSlider?.items.map((item, index) => (
          <FooterReassuranceItem
            className={css.item}
            {...item}
            key={`reassurance_item_mobile_${index}`}
            icon={item?.image}
            type="small"
          />
        ))}
      </Slider>
    </div>
  )
}

export default ReinsurranceSlider
