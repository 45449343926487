import type { SelectHTMLAttributes } from "react"
import { clsx } from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import Icon from "~/components/abstracts/Icon"
import type { Dictionary } from "~/providers/I18nProvider/constants"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export type SelectOption = { label: string; value: string | number; disabled: boolean }
type SelectOptions = SelectOption[]

export type SizeSelectProps = PropsWithClassName<SelectHTMLAttributes<HTMLSelectElement>> & {
  options: SelectOptions
  disableBorders?: boolean
}

function SizeSelect({ className, options, name, disableBorders = false, ...props }: SizeSelectProps) {
  const label = useGetTranslatedLabel(name)
  const t = useTranslate()
  const hasMoreThanOneValue = Array.isArray(options) ? options?.length > 1 : false
  const currentValue = hasMoreThanOneValue
    ? options?.find((option) => props?.defaultValue === option.value)?.label
    : options?.[0]?.label

  // needed to force only the color on one line
  const isColor =
    LABELS_TRANSLATE_KEYS?.[name?.toLowerCase() as keyof typeof LABELS_TRANSLATE_KEYS] === LABELS_TRANSLATE_KEYS.color

  const pointerEvents = hasMoreThanOneValue ? "all" : "none"
  const borderClassName = !disableBorders && !isColor ? css.withBorder : null
  const fullWidthClassName = isColor ? css.fullWidth : null

  return (
    <div className={clsx(className, css.SizeSelect, borderClassName, fullWidthClassName)}>
      <select {...props} className={clsx(css.element, sprinkles({ pointerEvents }))}>
        {options?.map((option, index) => {
          const { label, disabled } = option
          return (
            <option
              key={`${index}-${option.value}`}
              {...option}
              disabled={disabled}
              label={disabled ? `${label} - ${t("out_of_stock")}` : label}
            />
          )
        })}
      </select>
      <div className={clsx(css.container)}>
        <p className={clsx(css.content)}>
          <label className={clsx(css.label)} htmlFor={label}>
            {label}:{" "}
          </label>
          <span>{currentValue}</span>
        </p>
        {hasMoreThanOneValue ? (
          <Icon className={clsx(css.icon)} name={"Chevron"} direction="bottom" theme="black" width="10px" />
        ) : null}
      </div>
    </div>
  )
}

export default SizeSelect

const LABELS_TRANSLATE_KEYS = {
  size: "cart_taille",
  taille: "cart_taille",
  color: "cart_couleur",
  couleur: "cart_couleur",
  qté: "cart_quantity",
} satisfies Record<string, keyof Dictionary>

function useGetTranslatedLabel(name: string | undefined) {
  const t = useTranslate()

  if (!name) return name

  const key = LABELS_TRANSLATE_KEYS?.[name.toLowerCase() as keyof typeof LABELS_TRANSLATE_KEYS]

  if (!key) return name

  return t(key)
}
