import { type TImage } from "~/components/ui/Image/_data/serializer"
import type { TVideo } from "~/components/ui/Video"

const primaryorder = ["5", "e", "g", "h", "f", "j", "k", "l", "1"]
//@TODO use this secondary order once the metafield is set up
const _secondaryOrder = ["1", "a", "b", "c", "d", "5"]

export type TMedia = TVideo | TImage

export function getImageKey(item: TImage) {
  const parts = item.src.split(".jpg")[0]?.split("-")
  return parts && parts[parts.length - 1]
}

export function orderProductImages(items: TImage[]) {
  const orderedItems = items.sort((a, b) => {
    const keyA = getImageKey(a)
    const keyB = getImageKey(b)

    const orderA = keyA ? primaryorder.indexOf(keyA) : -1
    const orderB = keyB ? primaryorder.indexOf(keyB) : -1

    return orderA - orderB
  })

  return orderedItems
}

export default function serializeProductHeaderMedias({
  images,
  videos,
}: {
  images: TImage[]
  videos: TVideo[]
}): TMedia[] {
  if (images?.length === 0 && videos?.length === 0) return []

  // we need to split our images in case a video is present as they belong to the second index
  const [firstImage, ...followingImages] = orderProductImages(images) ?? []

  return [firstImage && firstImage, ...videos, ...followingImages].filter(Boolean)
}
