import type { UnlikelyVariant } from "@unlikelystudio/commerce-connector"

import { findOptionIndex } from "~/lib/shopify/utils/order-options"
import useLocale from "~/hooks/useLocale"
import type { SelectOption } from "~/components/ui/CartPanel/SizeSelect"
import { getAvailableForSaleVariant, serializeVariants } from "~/components/ui/ProductHeader/_data/serialize-variant"
import { objectEntries } from "~/utils/object-entries"

export function useSerializeOptions(variants: UnlikelyVariant[]) {
  const locale = useLocale()

  // get our ordered serialized variants
  const serializedVariants = serializeVariants(variants, locale).map((variant) => variant.id)

  // sort variants by the order of serializedVariants
  const orderedVariants = variants.sort((a, b) => {
    return serializedVariants.indexOf(a.id) - serializedVariants.indexOf(b.id)
  })

  const serializedOptions = orderedVariants.sort()?.reduce((acc, variant) => {
    const disabled = !getAvailableForSaleVariant(variant, locale)

    for (const option of variant.selectedOptions ?? []) {
      if (!option?.name || !option?.value || !variant?.id) continue

      const processedOption = {
        value: variant?.id,
        label: option?.value,
        disabled,
      } as SelectOption

      const optionName = option.name

      // Assign to acc[optionName] if it doesn't exist
      acc[optionName] ??= [processedOption]

      if (!acc?.[optionName]?.some((el) => el.label === option?.value)) {
        acc?.[optionName]?.push(processedOption)
      }
    }
    return acc
  }, {} as Record<string, SelectOption[]>)

  return objectEntries(serializedOptions)?.sort(([optionA], [optionB]) => {
    return findOptionIndex(optionA) > findOptionIndex(optionB) ? -1 : 1
  })
}
