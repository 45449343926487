import { TImage } from "~/components/ui/Image/_data/serializer"
import { getImageKey } from "~/components/ui/ProductHeader/components/ProductMedias/_data/serializer"

export function getPackshotFromImages(images: TImage[]) {
  const packshot = images.find((image) => getImageKey(image) === "1")

  if (packshot) {
    return {
      image: packshot,
      isPackshot: true,
    }
  }

  return {
    image: images[0] ?? null,
    isPackshot: false,
  }
}
